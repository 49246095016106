<template>
  <div>
    <header class="site-header bg-gray-50 shadow-md p-6 min-h-60 flex-none">
      <h2><img src="https://assets.codepen.io/3696383/p1-logo-dark.svg" /><span class="sr-only">ParticleOne</span></h2>
    </header>
    <div class="relative overflow-hidden bg-white my-4 py-2">
      <h1 class="py-8 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">ParticleOne End-User License Agreement</h1>
      <div class="mx-auto max-w-prose text-xl leading-8 text-gray-500">
        <LoadingSpinner v-if="licenseIsLoading" class="mt-8 mx-auto h-12 w-12" />
        <template v-else>
          <p v-if="success" class="text-2xl text-center">Success! Redirecting...</p>
          <template v-else>
            <div v-if="!licenseData.user_is_current">
              <p class="p-4">
                <span v-html="licenseData.eula_html"></span>
              </p>
              <div class="mx-4">
                <div class="sm:col-span-2">
                  <div class="flex items-start my-4">
                    <div class="flex-shrink-0">
                      <Switch v-model="agreed"
                        :class="[agreed ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2']">
                        <span class="sr-only">Agree to policies</span>
                        <span aria-hidden="true"
                          :class="[agreed ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                      </Switch>
                    </div>
                    <div class="ml-3">
                      <p class="text-base text-gray-500">
                        I have read and agree to the terms of service
                      </p>
                    </div>
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <button :disabled="!agreed" @click="onAccept"
                    class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-pink-600 px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                    :class="[agreed ? 'hover:bg-pink-700' : 'cursor-not-allowed']">Submit
                  </button>
                </div>
              </div>
            </div>
            <p v-else>You have already accepted our terms and conditions!<br /> Click <router-link
                class="font-medium text-pink-600" to="/">here</router-link>
              to
              return to the landing page</p>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingSpinner from '@/components/spinners/LoadingSpinner.vue';
import { Switch } from '@headlessui/vue';

export default {
  name: 'LicenseAgreement',
  components: {
    Switch,
    LoadingSpinner
  },
  data() {
    return {
      agreed: false,
      success: false,
      licenseIsLoading: true,
    };
  },
  async mounted() {
    await this.getLicenseData();
    this.licenseIsLoading = false;
  },
  methods: {
    ...mapActions(['getLicenseData', 'acceptLicenseAgreement']),
    async onAccept() {
      if (this.licenseData.eula_id) {
        this.licenseIsLoading = true;
        await this.acceptLicenseAgreement(this.licenseData.eula_id);
        this.licenseIsLoading = false;
        this.success = true;
        setTimeout(async () => {
          const redirect_from = localStorage.getItem('redirect_from');
          if (redirect_from) {
            await this.$router.push(redirect_from);
            localStorage.removeItem('redirect_from');
          } else {
            this.$router.push('/tenants/');
          }
        }, 1000);
      }
    }
  },
  computed: {
    ...mapGetters(['licenseData']),
  },
};
</script>

<style scoped>

</style>